
import Vue from 'vue';
import draggable from 'vuedraggable';
import { OutputSlide } from '@/shared/legacy/classes';
import { mapGetters, mapState } from 'vuex';

import { Models, Services } from '@/injectables/tokens';
import Slide from '@/components/Output/Slide.vue';
import SlideActionsMenu, { SlideAction } from '@/components/Output/Slide/SlideActionsMenu.vue';
import { SlideVisibility } from '@/app/graphql';

export default Vue.extend({
  name: 'OutputSidebar',

  components: { Slide, draggable, SlideActionsMenu },

  inject: ['$confirm', 'showSnackbar'],

  useInjectable: [Services.Output, Models.Output],

  data: (): {
    previewSlideWidth: number;
    isReordering: boolean;
    slideIdShouldMakeActive: string;
    stagedSlide: OutputSlide | null;
    saveSlideLoading: string[];
  } => ({
    saveSlideLoading: [],
    stagedSlide: null,
    slideIdShouldMakeActive: '',
    isReordering: false,
    previewSlideWidth: 240,
  }),

  props: {
    value: {
      type: Array,
      default: () => [],
    },
    startIndex: {
      type: Number,
      default: 0,
    },
    activeSlideId: {
      type: String,
      default: '',
    },
    isManageLayouts: {
      type: Boolean,
      default: false,
    },
    isAgencyAdmin: {
      type: Boolean,
      default: false,
    },
    isChangeDisabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState('agency', { agency: 'currentAgencyInfo' }),
    ...mapGetters('auth', ['isAdmin']),
    nonDeletedSlides(): OutputSlide[] {
      return this.slides.filter(slide => !this.isDeleted(slide));
    },
    slides: {
      get(): OutputSlide[] {
        return this.value;
      },
      set(slides: OutputSlide[]): void {
        this.$emit('input', slides);
      },
    },
    dragOptions() {
      return {
        animation: 0,
        group: 'description',
      };
    },
  },

  methods: {
    getSlideIndex(slide) {
      const slideIndex = this.nonDeletedSlides.findIndex(
        sl => (slide.id && sl.id === slide.id) || sl.name === slide.name,
      );

      return this.startIndex + slideIndex + 1;
    },
    isDeleted(slide: OutputSlide): boolean {
      return slide.visibility === SlideVisibility.Deleted;
    },
    slideActionsMenuItems(slide: OutputSlide): SlideAction[] {
      const actions: SlideAction[] = [];

      if (!this.isManageLayouts || !slide.defaultSlide) {
        actions.push({ action: 'Delete', eventName: 'delete-slide' });
      }

      if (!this.isManageLayouts) actions.push({ action: 'Duplicate', eventName: 'duplicate-slide' });

      const visibility = this.slideIsHidden(slide) ? 'Unhide' : 'Hide';

      actions.push({ action: visibility, eventName: 'toggle-visibility' });

      if (!slide.readonly) actions.push({ action: 'Save to Library', eventName: 'save-to-library' });

      if (this.isManageLayouts && !slide.readonly && slide.defaultSlide && (this.isAdmin || this.isAgencyAdmin)) {
        actions.push({
          action: 'Reset to default',
          eventName: 'reset-to-default',
        });
      }

      return actions;
    },
    variantText({ templateVariation = null }: OutputSlide): string {
      let text = '';
      if (templateVariation) {
        const key = Object.keys(templateVariation)?.[0] || 'key';
        const condition = Object.keys(templateVariation?.[key])?.[0] || 'condition';
        const value = templateVariation?.[key]?.[condition]?.toString() || 'value';
        text = `${key}: ${condition} ${value}`;
        return text;
      }
      return text;
    },
    openAddSlideDialog({ slide }: { slide: OutputSlide }): void {
      this.$emit('open-add-slide-dialog', slide);
    },
    onStart(e): void {
      this.isReordering = true;
      this.slideIdShouldMakeActive = e?.item?.id?.replace('prev_', '') || '';
    },
    onEnd(): void {
      this.isReordering = false;
    },
    deleteSlide(slide: OutputSlide): void {
      this.$store.dispatch('output/deleteSlide', { slide, isTemplate: this.isManageLayouts });
    },
    isActiveSlide(str: string): boolean {
      return this.activeSlideId === str;
    },
    toggleSlideVisibility(slide: OutputSlide): void {
      this.$store.dispatch('output/toggleSlideVisibility', { slide });
    },
    duplicateSlide(slide: OutputSlide): void {
      const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
        PropertyId,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
        id,
        map = null,
        order,
        name,
        layout = this.$store.state.output.activeOutput.layout,
        ...partialSlide
      } = slide;
      const newOrder = order ? order - 5 : 10;
      const nameArray = (name ?? '').split('_');
      const cleanName = nameArray?.[1] || nameArray[0];
      const newName = `dup_${cleanName}_${Date.now()}`;
      let newMap;
      if (map?.mapData?.mapId) {
        newMap = { ...map, mapData: { ...map.mapData, mapId: `${map.mapType}-${Date.now()}` } };
      }
      const duplicatedSlide = {
        ...partialSlide,
        _id: `s-${newOrder}`,
        order: newOrder,
        name: newName,
        fromLibrary: true,
        layoutId: layout,
        ...(newMap ? { map: newMap } : {}),
      };
      this.$store.dispatch('output/addSlide', [duplicatedSlide]).catch(err => {
        // eslint-disable-next-line no-console
        console.error('OutputSidebar/duplicateSlide', err);
        this.$store.dispatch('showSnackbar', { content: 'Unable to duplicate slide', color: 'error' });
      });
    },
    slideIsHidden(slide: OutputSlide): boolean {
      return slide?.visibility === SlideVisibility.Hidden;
    },
    setActiveSlide(str: string): void {
      this.$emit('set-active-slide', str);
    },
    async resetToDefault(slide: OutputSlide) {
      this.$emit('reset-slide', slide);
    },
  },
});
