
import Vue, { PropType } from 'vue';

export interface SlideAction {
  action: string;
  eventName: string;
}

export default Vue.extend({
  name: 'SlideActionsMenu',
  props: {
    slideActions: {
      type: Array as PropType<SlideAction[]>,
      default: () => [],
    },
    activatorTop: {
      type: String,
      default: '0',
    },
    activatorRight: {
      type: String,
      default: '0',
    },
    slideLoading: {
      type: Boolean,
      default: false,
    },
    activatorColor: {
      type: String,
      default: 'primary',
    },
  },
});
